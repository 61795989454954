<style scoped>
.platform-buttom-list{
    margin-top: -43px !important;
}
</style>
<template>
    <div class="platform-bottom-layout">
        <div class="platform-buttom-list">
          <div :class="bottomTaskType===1? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(1)">公益客户管理</div>
          <div :class="bottomTaskType===2? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(2)">公益销售管理</div>
        </div>

        <component :is="currentComponent + 'Component'" ref="bottom"  :id.sync="id" @on-create="createCustomer" />
    </div>
</template>

<script>
export default {
  components: {
    customerComponent: (resolve) => {
      require(['./bottom/Customer'], resolve)
    },
    salerComponent: (resolve) => {
      require(['./bottom/Saler'], resolve)
    }
  },
  data () {
    return {
      id: 0,
      bottomTaskType: 1,
      currentComponent: 'customer'
    }
  },
  created () {
  },
  methods: {
    handleChangeTag (val) {
      this.bottomTaskType = val
      if (val === 1) {
        this.currentComponent = 'customer'
      } else if (val === 2) {
        this.currentComponent = 'saler'
      }
    },
    reload () {
      this.$refs.bottom.reload()
    },
    createCustomer () {
      this.$emit('on-showLeft')
    }
  }
}
</script>
